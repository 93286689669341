import { Icon } from "@iconify/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Link from "next/link";
import { useEffect, useRef } from "react";
import {
  GetEstimation,
  StartBuildingMain,
  StartInnerText,
} from "../../StyledComponents/LandingStyled";
import MarqueeLogoCertificate from "../Common/MarqueeLogoCertificate";
import CMMIDEV3Svg from "../svg/Home/CMMIDEV3Svg";
import G2LogoSvg from "../svg/Home/G2LogoSvg";
import GoodFirmsSvg from "../svg/Home/GoodFirmsSvg";
import ISO270032013Svg from "../svg/Home/ISO270032013Svg";
import ISO90012015Svg from "../svg/Home/ISO90012015Svg";
import SoftwareWorldLogoSvg from "../svg/Home/SoftwareWorldLogoSvg";
import { MindsLeftButton } from "@/StyledComponents/HomeStyled";

gsap.registerPlugin(ScrollTrigger);

export default function StartBuilding() {
  const arrowRef = useRef(null);
  const logosRef = useRef([]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      // Arrow Animation (Constant)
      gsap.to(arrowRef.current, {
        x: 10,
        duration: 0.7,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });

      // Logos Animation (Bottom to Top, One by One)
      gsap.fromTo(
        logosRef.current,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          stagger: 0.3, // Delay between each logo appearing
          scrollTrigger: {
            trigger: logosRef.current[0], // Trigger animation when first logo comes into view
            start: "top 80%",
            toggleActions: "play reverse play reverse",
          },
        }
      );

      // Refresh ScrollTrigger on load
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    });

    return () => ctx.revert();
  }, []);

  const logoLinks = [
    "https://www.g2.com/products/it-services-india/reviews",
    "https://www.goodfirms.co/company/it-services-india",
    "https://www.softwareworld.co/service/it-services-india-reviews/",
    "https://www.iafcertsearch.org/certified-entity/75veFNFwwS7owds8gH8C1RUV",
    "https://www.iafcertsearch.org/certified-entity/75veFNFwwS7owds8gH8C1RUV",
    "/"
  ];
  return (
    <StartBuildingMain className="container p-b-section startbuild">
      <div className="hidden md:block lg:block">
        <div className="grid gap-20 justify-between grid-cols-5 justify-items-center items-center lg:flex lg:items-center lg:justify-between capitalize">
          {[G2LogoSvg, GoodFirmsSvg, SoftwareWorldLogoSvg, ISO90012015Svg, ISO270032013Svg, CMMIDEV3Svg].map((Logo, index) => (
            <Link
              key={index}
              href={logoLinks[index]}
              target="_blank"
              ref={(el) => (logosRef.current[index] = el)}
              aria-label={`Logo ${index + 1}`}
            >
              <Logo />
            </Link>
          ))}
        </div>
      </div>
      <div className="scrolllogo-first block md:hidden lg:hidden">
        <MarqueeLogoCertificate />
      </div>
      <StartInnerText>
        Let’s Transform your Ideas into <br className="hidden lg:block" />
        Tech Success!
      </StartInnerText>
      
      <MindsLeftButton>
                <a
                  href="/lets-talk"
                  target="_self"
                  className="h-btn btn dark-btn common-btn-hover !mt-[30px]"
                >
                  Get estimation{" "}
                  <span ref={arrowRef}>
                    <Icon icon="tabler:arrow-right" />
                  </span>
                </a>
              </MindsLeftButton>
    </StartBuildingMain>
  );
}
